<template>
    <div>
        <v-card flat>
          <div>
            <v-container class="grey lighten-5 fill-height">
                <v-card width="100%" :shaped="true">
                  <!-- <v-card-title> -->
                    <div class="align-center" style="background: #FFFFFF; box-shadow: 0px 3px 12px #0000000F; margin-bottom: 16px; width: 100%; height: 80px;">
                      <v-row align="center" style="height: 70px; margin-left: 10px;">
                        <v-col :md="1">
                          <v-btn text icon large>
                            <v-icon>mdi-arrow-left</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col>
                          <span class="title" style="color: #00B082">Haircut - Short</span>
                          <p class="subtitle-1" style="color: #BEC2C4">Djemari Salon and Spa</p>
                        </v-col>
                      </v-row>
                    </div>
                  <!-- </v-card-title> -->
                <v-row
                class="mb-6"
                no-gutters
                style="margin-left: 24px">
                <v-col :md="4" style="margin-right: 16px">
                    <v-card class="d-flex align-center justify-center" height="216px" :raised="true" >
                        <div class="text-center">
                          <div class="subtitle-1" style="color: #BEC2C4">Price</div>
                          <span class="display-1 font-weight-bold" >Rp. 50.000,00</span>
                        </div>
                    </v-card>
                </v-col>
                <v-col style="margin-right: 16px">
                    <div style="height: 216px">
                        <v-row>
                            <v-col style="padding: 5px">
                                <v-card class="d-flex align-center justify-center" height="100px" :raised="true" >
                                    <div class="text-center">
                                    <div class="subtitle-1" style="color: #BEC2C4">Total Booked</div>
                                    <span class="display-1 font-weight-bold" >2590</span>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col style="padding: 5px">
                                <v-card class="d-flex align-center justify-center" height="100px" :raised="true" >
                                    <div class="text-center">
                                    <div class="subtitle-1" style="color: #BEC2C4">Total Cancelled</div>
                                    <span class="display-1 font-weight-bold" style="color: #E84118" >2590</span>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col :md="6" style="margin-right: 16px">
                    <v-card class="d-flex align-center justify-center" height="216px" :raised="true" >
                        <div class="text-center">
                        <div class="subtitle-1" style="color: #BEC2C4">Revenue</div>
                        <span class="display-1 font-weight-bold" style="color: #4CD137">Rp 129.700.000</span>
                        </div>
                        <div style="position: absolute; bottom: 0px; background: #00D79E; height: 8px; border-top-right-radius: 5px; border-top-left-radius: 5px; width: 100%"></div>
                    </v-card>
                </v-col>
                </v-row>
                <v-card class="pa-2 mb-6" :shaped="true" :raised="true" width="100%" style="margin-left: 24px">
                <v-card-title style="height: 64px">
                    <v-row class="mb-6" no-gutters>
                    <v-col :md="6">
                        Sales Graph
                    </v-col>
                    <v-col :md="4">
                        <div class="float-right" style="margin-right: 8px">
                        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                            :value="computedDateFormattedMomentjs"
                            readonly
                            v-on="on"
                            solo
                            background-color="#F5F5F5"
                            style="width: 200px"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            @click:date="setDate(0)"
                            range
                        ></v-date-picker>
                        </v-menu>
                        </div>
                    </v-col>
                    <v-col :md="2">
                        <v-autocomplete
                        v-model="selectCity"
                        :items="dropdown_font"
                        solo
                        label="All City"
                        background-color="#F5F5F5"
                        ></v-autocomplete>
                    </v-col>
                    </v-row>
                </v-card-title>
                <ve-line :data="dataLine" :legend-visible="true" :colors="colorsLine" :settings="lineSettings" :extend="extend" :xAxis="xaxis" :after-config="afterConfig" />
                <v-card class="pa-2" :shaped="true" >
                    <v-card-title>
                        <div>
                            <span class="title"> Sales Detail </span>
                            <p class="body-1" style="color: #BEC2C4">10 Location</p>
                        </div>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                      ></v-text-field>
                      <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-cloud-download</v-icon>Download</v-btn>
                      <v-btn rounded outlined style="margin-right: 8px"><v-icon left>mdi-printer</v-icon>Print</v-btn>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="desserts"
                      :search="search"
                      hide-default-footer
                      class="mytable"
                      style="border-bottom: none"
                    >
                    <template v-slot:header.city="{ header }">
                      <span style="color: #00B082">{{ header.text }}</span>
                    </template>
                    <template v-slot:item.revenue="{ item }">
                      <span style="color: #4CD137">{{ item.revenue}}</span>
                    </template>
                    <template v-slot:item.cancelled="{ item }">
                      <span style="color: #E84118">{{ item.cancelled}}</span>
                    </template>
                    </v-data-table>

                    <v-spacer></v-spacer><br>
                    <v-pagination
                      v-model="page"
                      color="#00B082"
                      :length="total_page"
                      :total-visible="7"
                      circle
                      ></v-pagination>
                  </v-card>
                </v-card>
                </v-card>
            </v-container>
            </div>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

export default {
  name: 'ListActivity',
  props: ['extend', 'page', 'total_page'],

  data () {
    return {
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        }
      ],
      headers: [
        {
          text: 'Location',
          align: 'left',
          value: 'location'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Total Booked',
          align: 'center',
          value: 'total_booked'
        },
        {
          text: 'Cancelled',
          align: 'center',
          value: 'cancelled'
        },
        {
          text: 'Revenue',
          align: 'right',
          value: 'revenue'
        }
      ],
      desserts: [
        {
          company_name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_booked: 12,
          platform: 'POS',
          joined_time: '13.00',
          date: '21 Agustus 2019 13:00',
          type: 1,
          service_name: 'Haircut - Short',
          revenue: '1.320',
          cancelled: '2',
          price: 15.000,
          location: 'Kotabaru'
        },
        {
          company_name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_booked: 12,
          platform: 'POS, Online',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 2,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          cancelled: '2',
          price: 15.000,
          location: 'Seturan'
        },
        {
          company_name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_booked: 12,
          platform: 'POS, Online, Marketplace',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 3,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          cancelled: '2',
          price: 15.000,
          location: 'Salakan'
        },
        {
          company_name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_booked: 12,
          platform: 'POS',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 1,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          cancelled: '2',
          price: 15.000,
          location: 'Sleman'
        }
      ],
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      tab2: null,
      itemsTab: [
        'Service', 'Product', 'Class'
      ],
      dataLine: {
        columns: ['date', 'appointment', 'cancelled'],
        rows: [{ 'date': '10', 'appointment': 10, 'cancelled': 5, 'classes': 2 },
          { 'date': '11', 'appointment': 20, 'cancelled': 20, 'classes': 6 },
          { 'date': '12', 'appointment': 17, 'cancelled': 16, 'classes': 5 },
          { 'date': '13', 'appointment': 28, 'cancelled': 24, 'classes': 10 }
        ]
      },
      dataLine2: {
        columns: ['date', 'product_sold'],
        rows: [{ 'date': '10', 'product_sold': 10, 'cancelled': 5, 'classes': 2 },
          { 'date': '11', 'product_sold': 20, 'cancelled': 20, 'classes': 6 },
          { 'date': '12', 'product_sold': 17, 'cancelled': 16, 'classes': 5 },
          { 'date': '13', 'product_sold': 28, 'cancelled': 24, 'classes': 10 }
        ]
      },
      colorsLine: ['#00D79E', '#FFB677', '#C7A8FF'],
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#E74C3C'
          }, {
            offset: 1,
            color: '#FFFFFF'
          }])
        }
      },
      xaxis: {
        type: 'category',
        boundaryGap: false
      },
      search: ''
    }
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    afterConfig (options) {
      options.series[0].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      }
      options.series[1].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#FFB677'
          }, {
            offset: 1,
            color: '#ffd0a8'
          }])
        }
      }
      return options
    }
  }
}
</script>
